<template>
  <div class="metrics__rating general">
    <CommonTitle :options="options_title" />
    <CommonTable :options="options_table" />
  </div>
</template>

<script>
import CommonTitle from "../../common/CommonTitle";
import CommonTable from "../../common/CommonTable";
import Axios from 'axios';

export default {
  name: "MetricRating",
  data() {
    return {
      options_title: {
        name: 'Рейтинг песен',
        id: 'metrics',
        actions: '',
      },
      options_table: {
        id: 'sounds',
        request: 'sounds/metrics',
        actions: '',
        ths: [
          { id: 'sound_id', name: 'ID', width: '10%', value: '', sort: true, sort_data: '', search: true },
          { id: 'name', name: 'Название песни', width: '22.5%', value: '', sort: true, sort_data: '', search: true },
          { id: 'quantity_currect_answer', name: 'Общее кол-во прав. ответов', width: '22.5%', value: '', sort: true, sort_data: '', search: true },
          { id: 'quantity_helps', name: 'Общее кол-во испол. подсказок', width: '22.5%', value: '', sort: true, sort_data: '', search: true },
          { id: 'guessing_time', name: 'Среднее время отгадывания, сек', width: '22.5%', value: '', sort: true, sort_data: '', search: true },
        ],
        table: {
          variables: ['sound_id', 'name', 'quantity_currect_answer', 'quantity_helps', 'guessing_time'],
          page: 0,
          limit: 10,
          total: 0,
          data: []
        }
      }
    };
  },
  mounted() {
    Axios
      .get(`/api/sounds/metrics`, {
        headers: {
          token: this.$store.getters.token
        },
        params: {
          limit: 10,
          page: 0
        }
      })
      .then(res => {
        this.options_table.table.data = res.data.data;
        this.options_table.table.page = 0;
        this.options_table.table.limit = 10;
        this.options_table.table.total = res.data.total;
      })
      .catch(err => {
        console.error(err.response);
      });
  },
  components: {
    CommonTitle,
    CommonTable,
  },
};
</script>

<style>
</style>