<template>
  <div class="chart__general game">
    <div class="chart">
      <GameChart v-if="loading" :period="period" :chart="chart" />
    </div>
    <GameOptions :options="options" :time="time" :timeunit="timeunit" @selectTime="selectTime" @changeTimeUnit="changeTimeUnit" @selectCharacteristics="selectCharacteristics" @selectPeriod="selectPeriod" />
  </div>
</template>

<script>
import GameChart from "./GameChart";
import GameOptions from "./GameOptions";
import periodFilter from "@/filters/period.filter";
import fullPeriodFilter from "@/filters/fullPeriod.filter";
import Axios from 'axios';

export default {
  name: "GameGeneral",
  data() {
    return {
      period: null,
      time: {
        to: 23,
        from: 0,
      },
      timeunit: 'day',
      loading: false,
      chart: {
        launchs:          { borderColor: "#E44965", backgroundColor: 'transparent', data: [] },
        games:            { borderColor: "#F89B85", backgroundColor: 'transparent', data: [] },
        average_duration: { borderColor: "#F1C3D0", backgroundColor: 'transparent', data: [] },
        amount_duration:  { borderColor: "#FBD97E", backgroundColor: 'transparent', data: [] },
        average_phrase:   { borderColor: "#AADE4F", backgroundColor: 'transparent', data: [] },
        sites:            { borderColor: "#60D394", backgroundColor: 'transparent', data: [] },
        platforms:        { borderColor: "#6CCEF6", backgroundColor: 'transparent', data: [] },
        ios:              { borderColor: "#5B34B8", backgroundColor: 'transparent', data: [] },
        web:              { borderColor: "#9999A4", backgroundColor: 'transparent', data: [] },
        android:          { borderColor: "#7F72FF", backgroundColor: 'transparent', data: [] },
      },
      options: [
        { color: '#E44965', _id: 'launchs', name: 'Количество запусков. шт', selected: false, additional: [] },
        { color: '#F89B85', _id: 'games', name: 'Количество игр, шт', selected: false, additional: [] },
        { color: '#F1C3D0', _id: 'average_duration', name: 'Средняя продолжительность игры, сек', selected: false, additional: [] },
        { color: '#FBD97E', _id: 'amount_duration', name: 'Суммарная продолжительность игры, сек', selected: false, additional: [] },
        { color: '#AADE4F', _id: 'average_phrase', name: 'Среднее количество отгаданных фраз, шт', selected: false, additional: [] },
        { color: '#60D394', _id: 'sites', name: 'Количество игроков, пришедших с сайтов, людей', selected: false, additional: [] },
        { color: '#6CCEF6', _id: 'platforms', name: 'Количество игроков, запускающих с систем, людей', selected: false, additional: [
          { color: '#7F72FF', _id: 'android', name: 'Android', selected: false },
          { color: '#5B34B8', _id: 'ios', name: 'iOS', selected: false },
          { color: '#9999A4', _id: 'web', name: 'ПК', selected: false },
        ] },
      ]
    }
  },
  mounted() {
    this.$bus.$on('hiddenChart', () => {
      this.changeLoading();
    });

    let end = new Date();
    let start = new Date();
    start.setDate(end.getDate() - 13);

    this.period = periodFilter([start, end]);
    this.fullPeriod = fullPeriodFilter([start, end]);
    this.changeLoading();
  },
  methods: {
    selectTime(time) {
      this.time = time;
      this.selectCharacteristics();
    },
    changeTimeUnit(unit) {
      this.timeunit = unit;
    },
    async selectPeriod(period) {
      this.period = periodFilter(period);
      this.fullPeriod = fullPeriodFilter(period);
      this.selectCharacteristics();
    },
    async selectCharacteristics() {
      await this.$bus.$emit('hiddenChart');
      this.getAnalytics();
    },
    getAnalytics() {
      const characteristics = {};
      this.options.forEach(el => {
        if (el.selected) characteristics[el._id] = true;
        else characteristics[el._id] = false;
        el.additional.forEach(el_ => {
          if (el_.selected) characteristics[el_._id] = true;
          else characteristics[el_._id] = false;
        })
      });

      for (let i in this.chart) {
        this.chart[i].data = [];
      }

      Axios
        .post(`/api/analytics/game`, {characteristics, period: this.fullPeriod, time: this.time},  {
          headers: {
            token: this.$store.getters.token
          },
        })
        .then(res => {
          res.data.forEach(el => {
            for (let i in this.chart) {
              if (el[i] != null && characteristics[i]) {
                this.chart[i].data.push(el[i]);
              }
            }
          });
          this.changeLoading();
        })
        .catch(err => {
          console.error(err.response);
        });
    },
    changeLoading() {
      this.loading = !this.loading;
    }
  },
  components: {
    GameOptions,
    GameChart,
  },
};
</script>

<style lang="scss" scoped>
.chart__general {
  width: 100%;
  display: flex;
  padding: 25px;
  .chart {
    width: 65%;
  }
  .options {
    width: 35%;
  }
}
</style>