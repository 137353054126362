<template>
  <div class="metrics__chart general">
    <CommonTitle :options="options_title" />
    <NoteGeneral />
  </div>
</template>

<script>
import CommonTitle from "../../common/CommonTitle";
import CommonTable from "../../common/CommonTable";
import NoteGeneral from "../charts/note/NoteGeneral";

export default {
  name: "MetricNote",
  data() {
    return {
      options_title: {
        name: "Метрики записей",
        id: "metrics",
        actions: "",
      },
    };
  },
  mounted() {},
  components: {
    CommonTitle,
    CommonTable,
    NoteGeneral,
  },
};
</script>

<style>
</style>