<template>
  <div class="chart__general game">
    <div class="chart">
      <NoteChart v-if="loading" :period="period" :chart="chart" />
    </div>
    <NoteOptions :options="options" @filterSounds="filterSounds" :sound_options="sound_options" :sounds="sounds" @selectPeriod="selectPeriod" @selectCharacteristics="selectCharacteristics" />
  </div>
</template>

<script>
import NoteChart from "./NoteChart";
import NoteOptions from "./NoteOptions";
import periodFilter from "@/filters/period.filter";
import fullPeriodFilter from "@/filters/fullPeriod.filter";
import Axios from 'axios';

export default {
  name: "NoteGeneral",
  data() {
    return {
      period: null,
      sounds: {
        names: null,
        fragments: null,
        fragments_order: null
      },
      selected_sound_id: null,
      loading: false,
      sound_options: [
        { id: 'name', name: 'Название песни', show: false, data: [], value: {name: '', sound_id: ''} },
        { id: 'fragment', name: 'Фрагмент песни', show: false, data: [], value: {fragment: '', sound_id: ''} },
        { id: 'fragment_order', name: 'Порядок слов', show: false, data: [], value: {fragment_order: '', sound_id: ''} },
      ],
      chart: {
        currect_answer_0: { borderColor: "#F89B85", backgroundColor: 'transparent', data: [] },
        currect_answer_1: { borderColor: "#F1C3D0", backgroundColor: 'transparent', data: [] },
        currect_answer_2: { borderColor: "#FBD97E", backgroundColor: 'transparent', data: [] },
        average_time_0:   { borderColor: "#60D394", backgroundColor: 'transparent', data: [] },
        average_time_1:   { borderColor: "#6CCEF6", backgroundColor: 'transparent', data: [] },
        average_time_2:   { borderColor: "#7F72FF", backgroundColor: 'transparent', data: [] },
        launch:           { borderColor: "#E44965", backgroundColor: 'transparent', data: [] },
        skip:             { borderColor: "#AADE4F", backgroundColor: 'transparent', data: [] },
      },
      options: [
        { color: '#E44965', _id: 'launch', name: 'Количество использований фрагмента песни, шт', selected: false },
        { color: '#F89B85', _id: 'currect_answer_0', name: 'Количество правильных ответов без подсказок, шт', selected: false },
        { color: '#F1C3D0', _id: 'currect_answer_1', name: 'Количество правильных ответов с 1 подсказкой, шт', selected: false },
        { color: '#FBD97E', _id: 'currect_answer_2', name: 'Количество правильных ответов с более 1 подсказки, шт', selected: false },
        { color: '#AADE4F', _id: 'skip', name: 'Количество отказов отгадывания, шт', selected: false },
        { color: '#60D394', _id: 'average_time_0', name: 'Среднее время отгадывания без подсказок, сек', selected: false },
        { color: '#6CCEF6', _id: 'average_time_1', name: 'Среднее время отгадывания с 1 подсказкой, сек', selected: false },
        { color: '#7F72FF', _id: 'average_time_2', name: 'Среднее время отгадывания с более 1 подсказки, сек', selected: false },
      ]
    }
  },
  mounted() {
    this.$bus.$on('hiddenChart', () => {
      this.changeLoading();
    });

    let end = new Date();
    let start = new Date();
    start.setDate(end.getDate() - 13);

    this.period = periodFilter([start, end]);
    this.fullPeriod = fullPeriodFilter([start, end], 'note');
    this.getSounds();
    this.changeLoading();
  },
  methods: {
    async selectPeriod(period) {
      this.period = periodFilter(period);
      this.fullPeriod = fullPeriodFilter(period, 'note');
      this.selectCharacteristics();
    },
    async selectCharacteristics() {
      for (let i = 0; i < this.sound_options.length; i++) {
        if (!this.selected_sound_id) return;
      }
      await this.$bus.$emit('hiddenChart');
      this.getAnalytics();
    },
    filterSounds({option, sound}) {
      if (option.id === 'name') {
        this.sound_options[1].data = this.sounds.fragments.filter(el => el.name === sound.name);
        this.sound_options[1].value = {fragment: '', sound_id: ''};
        this.sound_options[2].value = {fragments_order: '', sound_id: ''};
        this.selected_sound_id = null;
        this.unselectedCharacterictics();
      }
      if (option.id === 'fragment') {
        this.sound_options[2].data = this.sounds.fragments_order.filter(el => el.fragment === sound.fragment && el.name === sound.name);
        this.sound_options[2].value = {fragments_order: '', sound_id: ''};
        this.selected_sound_id = null;
        this.unselectedCharacterictics();
      }
      if (option.id === 'fragment_order') {
        this.selected_sound_id = sound._id;
      }
    },
    async unselectedCharacterictics() {
      this.options.forEach(el => el.selected = false);
      for (let el in this.chart) {
        this.chart[el].data = [];
      }
      await this.$bus.$emit('hiddenChart');
      this.changeLoading();
    },
    getSounds() {
      Axios
        .get(`/api/analytics/sounds`, {
          headers: {
            token: this.$store.getters.token
          }
        })
        .then(res => {
          this.sounds.total = res.data;
          this.sounds.names = res.data.map(el => {
            return {name: el.name, _id: el._id}
          });
          this.sounds.fragments = res.data.map(el => {
            return {name: el.name, _id: el._id, fragment: el.fragment}
          });
          this.sounds.fragments_order = res.data.map(el => {
            return {name: el.name, _id: el._id, fragment: el.fragment, fragment_order: el.fragment_order}
          });

          this.unique(this.sounds.names, 'name');
          this.unique(this.sounds.fragments, 'fragment');

          this.sound_options[0].data = this.sounds.names;
          this.sound_options[1].data = this.sounds.fragments;
          this.sound_options[2].data = this.sounds.fragments_order;
        })
        .catch(err => {
          console.error(err.response);
        });
    },
    unique(array, prop) {
      for (let i = 0; i < array.length - 1; i++) {
        const ii = array[i];
        for (let j = i + 1; j < array.length; j++) {
          const jj = array[j];
          if (ii[prop] === jj[prop]) {
            array.splice(j, 1);
            j--;
          }
        }
      }
    },
    getAnalytics() {
      const characteristics = {};
      this.options.forEach(el => {
        if (el.selected) characteristics[el._id] = true;
        else characteristics[el._id] = false;
      });

      for (let i in this.chart) {
        this.chart[i].data = [];
      }

      Axios
        .post(`/api/analytics/note`, {characteristics, period: this.fullPeriod, sound_id: this.selected_sound_id},  {
          headers: {
            token: this.$store.getters.token
          },
        })
        .then(res => {
          res.data.forEach(el => {
            for (let i in this.chart) {
              if (el[i] != null && characteristics[i]) {
                this.chart[i].data.push(el[i]);
              }
            }
          });
          this.changeLoading();
        })
        .catch(err => {
          console.error(err.response);
        });
    },
    changeLoading() {
      this.loading = !this.loading;
    }
  },
  components: {
    NoteOptions,
    NoteChart,
  },
};
</script>

<style lang="scss" scoped>
.chart__general {
  width: 100%;
  display: flex;
  padding: 25px;
  .chart {
    width: 65%;
  }
  .options {
    width: 35%;
  }
}
</style>