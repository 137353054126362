import dateFilter from './date.filter';

export default function(period, chart = 'game') {
  const array = period.slice();
  const start = array[0];
  const end = array[1];
  const length = Math.ceil(Math.abs(end.getTime() - start.getTime()) / (1000 * 3600 * 24));
  const array_date = [];

  for (let i = 0; i <= length; i++) {
    let date = new Date(start);
    date.setDate(start.getDate() + i);
    if (chart === 'game') {
      array_date.push({date: dateFilter(date, 'date', true, true), launchs: 0, games: 0, amount_duration: 0, average_duration: 0, average_phrase: 0, platforms: 0, ios: 0, android: 0, web: 0});
    } else {
      array_date.push({date: dateFilter(date, 'date', true, true), currect_answer_0: 0, currect_answer_1: 0, currect_answer_2: 0, average_time_0: 0, average_time_1: 0, average_time_2: 0, skip: 0, launch: 0});
    }
  }
  return array_date;
}