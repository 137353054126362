<template>
  <MetricsMain />
</template>

<script>
import MetricsMain from '@/components/metrics/MetricsMain';

export default {
  name: 'Metrics',
  components: {
    MetricsMain
  }
}
</script>

<style>

</style>