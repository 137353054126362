<template>
  <section class="metrics">
    <div class="metrics__list">
      <div v-for="metric of metrics" :key="metric.name" :class="{active: selectedMetrics == metric.component}" @click="selectedMetrics = metric.component">{{ metric.name }}</div>
    </div>
    <component :is="selectedMetrics"></component>
  </section>
</template>

<script>
import MetricsGame from './metric/MetricsGame';
import MetricsNote from './metric/MetricsNote';
import MetricsRating from './metric/MetricsRating';

export default {
  name: 'MetricsMain',
  data() {
    return {
      selectedMetrics: MetricsGame,
      metrics: [
        { name: 'Метрики игры', component: MetricsGame },
        { name: 'Метрики записей', component: MetricsNote },
        { name: 'Рейтинг песен', component: MetricsRating },
      ],
    }
  },
  mounted() {
    
  },
}
</script>

<style lang="scss" scoped>
.metrics {
  flex-grow: 1;
  padding: 25px;
  overflow: auto;
  &__list {
    display: flex;
    margin-bottom: 30px;
    > div {
      color: #00325C;
      font-weight: 500;
      font-size: 20px;
      cursor: pointer;
      padding: 5px 12px 15px 12px;
      &:not(:last-child) {
        margin-right: 5px;
      }
      &:hover {
        color: #054b85;
        transition: 0.3s all;
      }
      &.active {
        box-shadow: inset 0 -3px 0 #6461F6;
      }
    }
  }
  &__container {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(4, 1fr);
    
  }
  .metric {
    display: flex;
    flex-direction: column;
  }
}
</style>