import dateFilter from './date.filter';

export default function(period) {
  const array = period.slice();
  const start = array[0];
  const end = array[1];
  const length = Math.ceil(Math.abs(end.getTime() - start.getTime()) / (1000 * 3600 * 24));
  const array_date = [];

  for (let i = 0; i <= length; i++) {
    let date = new Date(start);
    date.setDate(start.getDate() + i);
    array_date.push({date: dateFilter(date, 'date', false)});
  }
  return array_date;
}