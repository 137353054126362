<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  name: "NoteChart",
  props: ['period', 'chart'],
  data() {
    Chart.defaults.global.defaultFontFamily = "'Montserrat', sans-serif";
    return {
      chartdata: {
        labels: this.period.map(el => el.date),
        datasets: [this.chart.currect_answer_0, this.chart.currect_answer_1, this.chart.currect_answer_2, this.chart.average_time_0, this.chart.average_time_1, this.chart.average_time_2, this.chart.skip, this.chart.launch]
      },
      chartoptions: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)"
              }
            }
          ]
        }
      },
    };
  },
  mounted() {
    this.renderChart(this.chartdata, this.chartoptions);
  },
};
</script>