<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  name: "GameChart",
  props: ["period", "chart"],
  data() {
    Chart.defaults.global.defaultFontFamily = "'Montserrat', sans-serif";
    return {
      chartdata: {
        labels: this.period.map(el => el.date),
        datasets: [this.chart.launchs, this.chart.games, this.chart.amount_duration, this.chart.average_duration, this.chart.average_phrase, this.chart.platforms, this.chart.web, this.chart.android, this.chart.ios]
      },
      chartoptions: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)"
              }
            }
          ]
        }
      },
    };
  },
  mounted() {
    this.renderChart(this.chartdata, this.chartoptions);
  },
};
</script>