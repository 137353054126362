<template>
  <div class="options">
    <div class="options__section">
      <div class="options__section_title">Дата и время</div>
      <div class="options__section_container">
        <div class="option">
          <div class="left">
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.1065 0.7182H12.1226V0.463974C12.1226 0.207736 11.9258 0 11.6831 0C11.4404 0 11.2437 0.207736 11.2437 0.463974V0.7182H10.031V0.463974C10.031 0.207736 9.83426 0 9.59156 0C9.34887 0 9.15211 0.207736 9.15211 0.463974V0.7182H7.93945V0.463974C7.93945 0.207736 7.7427 0 7.5 0C7.2573 0 7.06055 0.207736 7.06055 0.463974V0.7182H5.84789V0.463974C5.84789 0.207736 5.65113 0 5.40844 0C5.16574 0 4.96898 0.207736 4.96898 0.463974V0.7182H3.75633V0.463974C3.75633 0.207736 3.55957 0 3.31687 0C3.07418 0 2.87742 0.207736 2.87742 0.463974V0.7182H1.89346C0.849404 0.7182 0 1.615 0 2.71728V12.0009C0 13.1032 0.849404 14 1.89346 14H13.1065C14.1506 14 15 13.1032 15 12.0009V2.71728C15 1.615 14.1506 0.7182 13.1065 0.7182ZM14.1211 12.0009C14.1211 12.5916 13.666 13.0721 13.1065 13.0721H1.89346C1.33403 13.0721 0.878906 12.5915 0.878906 12.0009V4.41251H14.1211V12.0009ZM14.1211 3.48457H0.878906V2.71728C0.878906 2.12667 1.33403 1.64615 1.89346 1.64615H2.87742V1.82552C2.87742 2.08176 3.07418 2.28949 3.31687 2.28949C3.55957 2.28949 3.75633 2.08176 3.75633 1.82552V1.64615H4.96898V1.82552C4.96898 2.08176 5.16574 2.28949 5.40844 2.28949C5.65113 2.28949 5.84789 2.08176 5.84789 1.82552V1.64615H7.06055V1.82552C7.06055 2.08176 7.2573 2.28949 7.5 2.28949C7.7427 2.28949 7.93945 2.08176 7.93945 1.82552V1.64615H9.15211V1.82552C9.15211 2.08176 9.34887 2.28949 9.59156 2.28949C9.83426 2.28949 10.031 2.08176 10.031 1.82552V1.64615H11.2437V1.82552C11.2437 2.08176 11.4404 2.28949 11.6831 2.28949C11.9258 2.28949 12.1226 2.08176 12.1226 1.82552V1.64615H13.1065C13.666 1.64615 14.1211 2.12667 14.1211 2.71728V3.48457Z" fill="#00325C"/>
              <path d="M4.89906 5.40167H3.16503C2.92234 5.40167 2.72559 5.60941 2.72559 5.86564V7.75984C2.72559 8.01608 2.92234 8.22381 3.16503 8.22381H4.89906C5.14175 8.22381 5.3385 8.01608 5.3385 7.75984V5.86564C5.3385 5.60941 5.14178 5.40167 4.89906 5.40167ZM4.45961 7.29588H3.60448V6.32961H4.45961V7.29588Z" fill="#00325C"/>
              <path d="M8.36683 5.40167H6.63281C6.39011 5.40167 6.19336 5.60941 6.19336 5.86564V7.75984C6.19336 8.01608 6.39011 8.22381 6.63281 8.22381H8.36683C8.60952 8.22381 8.80627 8.01608 8.80627 7.75984V5.86564C8.80627 5.60941 8.60952 5.40167 8.36683 5.40167ZM7.92738 7.29588H7.07225V6.32961H7.92738V7.29588Z" fill="#00325C"/>
              <path d="M11.8356 5.40167H10.1016C9.85886 5.40167 9.66211 5.60941 9.66211 5.86564V7.75984C9.66211 8.01608 9.85886 8.22381 10.1016 8.22381H11.8356C12.0783 8.22381 12.275 8.01608 12.275 7.75984V5.86564C12.275 5.60941 12.0783 5.40167 11.8356 5.40167ZM11.3961 7.29588H10.541V6.32961H11.3961V7.29588Z" fill="#00325C"/>
              <path d="M4.89906 8.99991H3.16503C2.92234 8.99991 2.72559 9.20765 2.72559 9.46388V11.3581C2.72559 11.6143 2.92234 11.8221 3.16503 11.8221H4.89906C5.14175 11.8221 5.3385 11.6143 5.3385 11.3581V9.46388C5.3385 9.20765 5.14178 8.99991 4.89906 8.99991ZM4.45961 10.8942H3.60448V9.92789H4.45961V10.8942Z" fill="#00325C"/>
              <path d="M8.36683 8.99991H6.63281C6.39011 8.99991 6.19336 9.20765 6.19336 9.46388V11.3581C6.19336 11.6143 6.39011 11.8221 6.63281 11.8221H8.36683C8.60952 11.8221 8.80627 11.6143 8.80627 11.3581V9.46388C8.80627 9.20765 8.60952 8.99991 8.36683 8.99991ZM7.92738 10.8942H7.07225V9.92789H7.92738V10.8942Z" fill="#00325C"/>
              <path d="M11.8356 8.99991H10.1016C9.85886 8.99991 9.66211 9.20765 9.66211 9.46388V11.3581C9.66211 11.6143 9.85886 11.8221 10.1016 11.8221H11.8356C12.0783 11.8221 12.275 11.6143 12.275 11.3581V9.46388C12.275 9.20765 12.0783 8.99991 11.8356 8.99991ZM11.3961 10.8942H10.541V9.92789H11.3961V10.8942Z" fill="#00325C"/>
            </svg>
          </div>
          <div class="right">
            <input type="text" ref="datepicker" placeholder="дд.мм.гг - дд.мм.гг" v-mask="'##.##.## - ##.##.##'">
            <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L3 3L5 1" stroke="#00325C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="options__section">
      <div class="options__section_title">Песня</div>
      <div class="options__section_container sound" v-if="sounds.names">
        <div class="option" v-for="option of sound_options" :key="option.id">
          <div class="right" @click="showSoundOption(option)">
            <input v-model="option.value[option.id]" type="text" :placeholder="option.name">
            <svg width="6" height="4" viewBox="0 0 6 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L3 3L5 1" stroke="#00325C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="drop" v-if="sounds.names && option.show">
              <ul>
                <li @click.stop="selectOption(option, sound)" v-for="sound of option.data" :key="sound._id">{{ sound[option.id] === '' ? 'Пустой' : sound[option.id] }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="options__section">
      <div class="options__section_title">Характеристики</div>
      <div class="options__section_container">
        <div class="option" @change="selectCharacteristics" v-for="option of options" :key="option._id">
          <div class="left">
            <input type="checkbox" :style="'border: 2px solid ' + option.color" v-model="option.selected">
          </div>
          <div class="right flex direction_column">
            <div class="text">{{ option.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import "@/utils/datepicker.min";

export default {
  name: 'NoteOptions',
  props: ['options', 'sounds', 'sound_options'],
  data() {
    return {

    }
  },
  mounted() {
    const $vm = this;
    $(this.$refs.datepicker).datepicker({
      maxDate: new Date(),
      dateFormat: "dd.mm.yy",
      range: true,
      multipleDatesSeparator: " - ",
      toggleSelected: false,
      onSelect(formattedDate, period) {
        if (period.length == 2) {
          const datepicker = $($vm.$refs.datepicker)
            .datepicker()
            .data("datepicker");
          datepicker.hide();

          $vm.$emit("selectPeriod", period);
        }
      }
    });
  },
  methods: {
    showSoundOption(option) {
      this.sound_options.forEach(el => {
        if (el.id !== option.id) el.show = false;
      });
      option.show = !option.show;
    },
    selectOption(option, sound) {
      option.value[option.id] = sound[option.id];
      option.value.sound_id = sound._id;
      this.$emit('filterSounds', {option, sound});
      this.sound_options.forEach(el => el.show = false);
    },
    selectCharacteristics() {
      this.$emit('selectCharacteristics');
    }
  }
}
</script>

<style lang="scss" scoped>
.options {
  padding-left: 40px;
  &__section {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &_title {
      font-size: 14px;
      font-weight: 500;
      color: #00325C;
      margin-bottom: 15px;
    }
    &_container {
      .option {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        .left {
          width: 30px;
          svg {
            width: 18px;
            height: 18px;
          }
          input {
            position: relative;
            top: 2px;
          }
        }
        .right {
          position: relative;
          input[type="text"] {
            border: 1px solid #E2E1EF;
            background-color: #fff;
            font-weight: 500;
            color: #00325C;
            padding: 8px 15px;
            &::-webkit-input-placeholder {
              color: #00325C;
              font-weight: 500;
            }
          }
          .text {
            color: #00325C;
            font-size: 13px;
            height: 20px;
            display: flex;
            align-items: center;
          }
          svg {
            position: absolute;
            width: 9px;
            height: 5px;
            top: 15px;
            right: 8px;
          }
          .additional_option {
            margin-top: 5px;
            display: flex;
            align-items: center;
          }
        }
      }
      .items_start {
        align-items: flex-start;
      }
      &.sound {
        .option {
          width: 50%;
          .right {
            width: 100%;
            position: relative;
            input {
              width: 100%;
              &.active {
                border-radius: 5px 5px 0 0;
              }
            }
            .drop {
              width: 100%;
              position: absolute;
              top: 98%;
              left: 0;
              background-color: #fff;
              z-index: 1;
              border-radius: 0 0 5px 5px;
              font-size: 13px;
              border: 1px solid #E2E1EF;
              max-height: 100px;
              overflow: auto;
              ul {
                li {
                  cursor: pointer;
                  padding: 8px 10px;
                  transition: 0.2s all;
                  &:hover {
                    background-color: #f6f7fc;
                    transition: 0.2s all;
                  }
                  &:not(:last-child) {
                    border-bottom: 1px solid #E2E1EF;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>